import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { selectedOrganizationSelector } from '../../../../redux/selector';
import { updateDraft, getDrafts } from '../../../../redux/services';
import {
  toast,
  resetAudienceBuilder,
  fullloader,
} from '../../../../redux/action';
import DraftCard from '../DraftCard';
import NewCard from '../NewCard';
import { Container, FullLengthContainer } from '../_PageUI/Page.styled';
import useCountCardsPerRow from '../_utils/useCountCardsPerRow';
import { ROWS_TO_LOAD, ACTIVE } from '../_utils/constants';

const DraftsPage = ({
  activeSortByValue,
  activeFilter,
  cardsBySearch,
  loadingCardsBySearch,
  searchValue,
  hasMoreSearchCardsToLoad,
  loadSearchCardsRef,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const observer = useRef();
  const orgId = useSelector(selectedOrganizationSelector).id;
  const cardsPerRow = useCountCardsPerRow();
  const [drafts, setDrafts] = useState([]);
  const [hasMoreDraftsToLoad, setHasMoreDraftsToLoad] = useState(false);
  const [loadingMoreDrafts, setLoadingMoreDrafts] = useState(false);
  const hasSomeDraftsRendered = searchValue
    ? cardsBySearch.length > 0
    : drafts.length > 0;

  const loadDraftCards = useCallback(async () => {
    try {
      setLoadingMoreDrafts(true);
      const skip = drafts.length;
      let limit = cardsPerRow * ROWS_TO_LOAD;
      if (!skip && activeFilter === ACTIVE.value) {
        limit -= 1;
      }

      const data = await getDrafts({
        orgId,
        sortby: activeSortByValue,
        skip,
        limit,
        filter: activeFilter,
      });

      setDrafts(prevDraftCards => [...prevDraftCards, ...data.result]);
      setHasMoreDraftsToLoad(data.left);
      setLoadingMoreDrafts(false);
    } catch (e) {
      dispatch(toast('error', 'Error loading drafts.'));
    }
  }, [
    activeFilter,
    activeSortByValue,
    cardsPerRow,
    dispatch,
    drafts.length,
    orgId,
  ]);

  // triggers next useEffect which triggers loadDraftCards function
  useEffect(() => {
    setDrafts([]);
  }, [activeSortByValue, activeFilter, orgId]);

  useEffect(() => {
    if (orgId && drafts.length === 0) {
      dispatch(fullloader(true, 'Loading Drafts ...'));
      loadDraftCards().finally(() => {
        dispatch(fullloader(false));
      });
    }
  }, [orgId, drafts.length, dispatch, loadDraftCards]);

  const lastElemComponentRef = lastElement => {
    if (hasMoreDraftsToLoad) {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          if (searchValue) {
            loadSearchCardsRef.current(searchValue);
          } else {
            loadDraftCards();
          }
        }
      });

      if (lastElement) observer.current.observe(lastElement);
    }
  };

  const openDraft = async draft => {
    dispatch(resetAudienceBuilder());
    history.push(`/dashboard/sendmessage?draft=${draft._id}`);
  };

  const archiveDraft = async draft => {
    try {
      await updateDraft({
        _id: draft._id,
        status: 'Archived',
      });
      dispatch(toast('success', 'Draft Archived.'));
      // this will trigger loading drafts
      setDrafts([]);
    } catch (e) {
      dispatch(toast('error', `Error archiving drafts. ${e.message}`));
    }
  };

  const unarchiveDraft = async draft => {
    try {
      await updateDraft({
        _id: draft._id,
        status: 'Active',
      });
      dispatch(toast('success', 'Draft Set Active.'));
      // this will trigger loading drafts
      setDrafts([]);
    } catch (e) {
      dispatch(toast('error', `Error setting active. ${e.message}`));
    }
  };

  const openNewDraft = () => {
    dispatch(resetAudienceBuilder());
    history.push('/dashboard/sendmessage?draft=new');
  };

  const getSearchResults = () => {
    if (loadingCardsBySearch && !cardsBySearch.length)
      return (
        <FullLengthContainer>
          <CircularProgress size={50} />
        </FullLengthContainer>
      );

    if (cardsBySearch.length)
      return (
        <>
          {cardsBySearch.map(draft => (
            <DraftCard
              key={draft._id}
              draft={draft}
              archive={() => {
                archiveDraft(draft);
              }}
              unarchive={() => {
                unarchiveDraft(draft);
              }}
              onClick={() => openDraft(draft)}
              reloadDrafts={() => {
                //this will trigger reloading drafts
                setDrafts([]);
              }}
              openDraft={openDraft}
            />
          ))}
          {hasMoreSearchCardsToLoad ? (
            loadingCardsBySearch ? (
              <FullLengthContainer>
                <CircularProgress size={50} />
              </FullLengthContainer>
            ) : (
              <FullLengthContainer ref={lastElemComponentRef} />
            )
          ) : null}
        </>
      );

    return <>No Drafts found.</>;
  };

  return (
    <>
      <Container>
        {cardsBySearch.length || loadingCardsBySearch || searchValue ? (
          getSearchResults()
        ) : (
          <>
            {activeFilter === ACTIVE.value && (
              <NewCard onClick={openNewDraft} title="Create a New Draft" />
            )}
            {drafts.map(draft => (
              <DraftCard
                key={draft._id}
                draft={draft}
                archive={() => {
                  archiveDraft(draft);
                }}
                unarchive={() => {
                  unarchiveDraft(draft);
                }}
                onClick={() => openDraft(draft)}
                reloadDrafts={() => {
                  //this will trigger reloading drafts
                  setDrafts([]);
                }}
                openDraft={openDraft}
              />
            ))}
            {hasSomeDraftsRendered ? (
              loadingMoreDrafts ? (
                <FullLengthContainer>
                  <CircularProgress size={50} />
                </FullLengthContainer>
              ) : (
                <FullLengthContainer ref={lastElemComponentRef} />
              )
            ) : null}
          </>
        )}
      </Container>
    </>
  );
};

export default DraftsPage;
