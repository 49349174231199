import axios from 'axios';
import { createGroup } from '../../../../../../redux/services/groups';
import { saveOptinContent } from '../../../../../../redux/services/optin';
import { updateKeyOrgContacts } from '../../../../../../redux/services/user';

const optinContentsDefaults = [
  {
    content:
      "Use this page to pick the industry information you'd like to stay current on - and thank you for being a proactive member with us!",
    contactType: 'member',
  },
  {
    content:
      'Use this page to tell us what you need from our organization and how you want it sent to you. For more valuable and privileged industry information, including for our local market, contact us about becoming a full member today!',
    contactType: 'nonMember',
  },
];

export const fetchPaperFormSubmissionData = async paperFormSubmissionId => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_paperformAPI}?submissionId=${paperFormSubmissionId}`
    );
    const { data } = response;
    return data;
  } catch (err) {
    throw new Error(
      `Failed to fetch PaperForm submission data for ${paperFormSubmissionId} - ${err}`
    );
  }
};

export const createNewOrgGroups = async newOrgData => {
  try {
    const groupsData = await newOrgData.groupsData;
    const createGroupPromises = groupsData.map(async _ => {
      await createGroup(_);
    });
    const res = await Promise.all(createGroupPromises);
    return res.length;
  } catch (err) {
    //No error is thrown because we don't want to kill the process just because this step fails
    console.error('Failed to create new groups for organization', err);
  }
};

export const createNewOrgTopics = async topicsData => {
  try {
    const createTopicsPromises = topicsData.map(async _ => {
      await createGroup(_);
    });
    const res = await Promise.all(createTopicsPromises);
    return res.length;
  } catch (err) {
    //No error is thrown because we don't want to kill the process just because this step fails
    console.error('Failed to create new topics for organization', err);
  }
};

export const createOptinContentDefaults = async newOrgId => {
  try {
    const saveOptinPromises = optinContentsDefaults.map(async content => {
      await saveOptinContent({
        id: newOrgId,
        data: { ...content, organizationId: newOrgId },
      });
    });
    const res = await Promise.all(saveOptinPromises);
    return res.length;
  } catch (err) {
    //No error is thrown because we don't want to kill the process just because this step fails
    console.error('Failed to create default optin contents for new org', err);
  }
};

const sanitizePhoneNumber = phone => {
  if (!phone || typeof phone !== 'string') return '';
  return phone.replace(/\D/g, ''); //Remove non-numerical values
};

export const createOrgKeyContacts = async keyContacts => {
  try {
    const formattedKeyContacts = keyContacts.map(contact => ({
      ...contact,
      phoneNumber: sanitizePhoneNumber(contact.phoneNumber),
    }));
    const res = await updateKeyOrgContacts(formattedKeyContacts);
    return !!res;
  } catch (err) {
    //No error is thrown because we don't want to kill the process just because this step fails
    console.error('Failed to create key contacts for new organization', err);
  }
};
