import React, { useCallback } from 'react';
import {
  CircularProgress,
  Modal as MuiModal,
  Tooltip,
} from '@material-ui/core';
import { ReactComponent as SaveIcon } from './saveIcon.svg';
import { Done, Clear, HighlightOff } from '@material-ui/icons';
import {
  Wrapper,
  Header,
  Title,
  Body,
  Footer,
  PrimaryButton,
  SecondaryButton,
  Subtitle,
  Column,
} from './MaterialUIModal.styled';

const Modal = ({
  title,
  subtitle,
  primaryButton: {
    label: primaryLabel,
    onClick: onPrimaryClick,
    isLoading: isPrimaryLoading = false,
    isDisabled: isPrimaryDisabled = false,
    isDelete,
  } = {},
  secondaryButton: { label: secondaryLabel, onClick: onSecondaryClick } = {},
  onClose,
  children,
}) => {
  const notifyClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handlePrimaryButtonClick = useCallback(() => {
    onPrimaryClick();
  }, [onPrimaryClick]);

  const handleSecondaryButtonClick = useCallback(() => {
    if (onSecondaryClick) {
      onSecondaryClick();
    } else {
      onClose();
    }
  }, [onClose, onSecondaryClick]);

  return (
    <MuiModal open onClose={notifyClose}>
      <Wrapper>
        <Header>
          <SaveIcon />
          <Column>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
          </Column>
        </Header>
        <Body>{children}</Body>
        <Footer>
          <SecondaryButton onClick={handleSecondaryButtonClick}>
            <Clear fontSize="small" />
            {secondaryLabel ?? 'Cancel'}
          </SecondaryButton>
          <Tooltip
            title="Please fill out all required fields"
            disableHoverListener={!isPrimaryDisabled}
          >
            <span>
              <PrimaryButton
                variant="outlined"
                disabled={isPrimaryLoading || isPrimaryDisabled}
                isDelete={isDelete}
                onClick={handlePrimaryButtonClick}
                startIcon={
                  isPrimaryLoading && (
                    <CircularProgress size={12} color="inherit" />
                  )
                }
              >
                {isDelete ? (
                  <HighlightOff fontSize="small" />
                ) : (
                  <Done fontSize="small" />
                )}
                {primaryLabel ?? 'Confirm'}
              </PrimaryButton>
            </span>
          </Tooltip>
        </Footer>
      </Wrapper>
    </MuiModal>
  );
};

export default Modal;
