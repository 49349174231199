import { Button, Col, Input, Row } from 'antd';
import { Box } from 'grommet';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  fullloader,
  toast,
  userOrganization,
} from '../../../../../../redux/action';
import PaperForm from './PaperForm';
import {
  uploadLogoDoc,
  createOrganization,
} from '../../../../../../redux/services';
import { userDetailSelector } from '../../../../../../redux/selectors/user';
import {
  fetchPaperFormSubmissionData,
  createNewOrgGroups,
  createNewOrgTopics,
  createOptinContentDefaults,
  createOrgKeyContacts,
} from './helpers';

const PaperFormSubmission = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [paperFormSubmissionId, setPaperFormSubmissionId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');

  const user = useSelector(userDetailSelector);

  useEffect(() => {
    dispatch(fullloader(isLoading, loadingMessage));
  }, [isLoading, loadingMessage]);

  const toastStepFailure = msg => {
    dispatch(toast('error', msg));
  };

  const createOrgFromPaperFormData = async () => {
    setIsLoading(true);
    setLoadingMessage('(1/7) Fetching submission data from PaperForm');

    try {
      const submissionData = await fetchPaperFormSubmissionData(
        paperFormSubmissionId
      );
      const orgName = submissionData.organizationName;

      const newOrganizationData = new PaperForm(submissionData);

      // create the organization
      setLoadingMessage(`(2/7) Adding ${orgName} to NoteRouter`);

      const newOrg = await createOrganization(newOrganizationData.orgData);

      setLoadingMessage('(3/7) Uploading organization logo (if provided)');
      if (newOrganizationData.orgData.logoData) {
        const logoSuccess = await uploadLogoDoc({
          ...newOrganizationData.orgData.logoData,
          userId: user.id,
        });
        if (!logoSuccess) toastStepFailure('Failed to upload org logo');
      }

      setLoadingMessage('(4/7) Uploading organization groups');
      const groupsSuccess = await createNewOrgGroups(newOrganizationData);
      if (!groupsSuccess) toastStepFailure('Failed to upload org groups');

      setLoadingMessage('(5/7) Uploading organization topics');
      const topicsSuccess = await createNewOrgTopics(
        newOrganizationData.topicsData
      );
      if (!topicsSuccess) toastStepFailure('Failed to upload org topics');

      // set preference defaults
      setLoadingMessage('(6/7) Creating default optin content copy');
      const optinContentSuccess = await createOptinContentDefaults(
        newOrganizationData.orgId
      );
      if (!optinContentSuccess)
        toastStepFailure('Failed to upload default optin content copy');

      setLoadingMessage('(7/7) Creating organization key contacts');
      const keyContactSuccess = await createOrgKeyContacts(
        newOrganizationData.keyContacts
      );
      if (!keyContactSuccess)
        toastStepFailure('Failed to upload org key contacts');

      dispatch(
        toast(
          'success',
          `Successfully created ${orgName} from PaperForm submission.`,
          5
        )
      );

      setIsLoading(false);
      dispatch(userOrganization(newOrg));
      history.push('/dashboard/organization');
    } catch (e) {
      console.error(
        `Error handling submission (${paperFormSubmissionId}) --->`,
        e
      );
      setIsLoading(false);
      toastStepFailure(
        'Failed to handle submission properly, check console for more detail.'
      );
    }
  };

  return (
    <Row style={{ marginBottom: '20px' }}>
      <Col>
        <Box direction="row" gap="medium">
          <Input
            onChange={e => {
              setPaperFormSubmissionId(e.target.value);
            }}
            onReset={() => setPaperFormSubmissionId('')}
            id="text-input-id"
            placeholder="Enter Submission ID"
          />
          <Button
            disabled={!paperFormSubmissionId}
            onClick={() => {
              createOrgFromPaperFormData(paperFormSubmissionId);
            }}
          >
            Submit
          </Button>
        </Box>
      </Col>
    </Row>
  );
};

export default PaperFormSubmission;
