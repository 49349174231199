import http from '../../../utils/andromeda-api';
import store from '../../../redux/store';
import { selectedOrganizationSelector } from '../../../redux/selectors/organization';

const getOrganizationId = () => {
  return selectedOrganizationSelector(store.getState()).id;
};

export const getRows = async () => {
  try {
    const organizationId = getOrganizationId();
    const {
      data: { result },
    } = await http.get(`/savedRows/${organizationId}`);
    return result;
  } catch (error) {
    console.error('Error in getRows:', error);
    throw error;
  }
};

export const getFolders = async () => {
  try {
    const organizationId = getOrganizationId();
    const {
      data: { result },
    } = await http.get(`/savedRows/folders/${organizationId}`);

    return {
      emptyRows: true,
      defaultRows: true,
      externalContentURLs: result.map(folder => ({
        name: folder.name,
        value: folder.id,
        handle: folder.id,
        isLocal: true,
        behaviors: { canEdit: true, canDelete: true },
      })),
    };
  } catch (error) {
    console.error('Error in getFolders:', error);
    throw error;
  }
};

export const saveRow = async ({ name, content, folderId, id }) => {
  try {
    if (!name || !content || !folderId)
      throw new Error('name, content and folderId are required fields');
    const organizationId = getOrganizationId();

    const { data } = await http.post('/savedRows', {
      organizationId,
      name,
      content,
      folderId,
      id,
    });

    return data;
  } catch (error) {
    console.error('Error in saveRow:', error);
    throw error;
  }
};

export const saveFolder = async ({ name, id }) => {
  try {
    if (!name || !id) throw new Error('name and id are required fields');
    const organizationId = getOrganizationId();

    const { data } = await http.post('/savedRows/folder', {
      organizationId,
      name,
      id,
    });

    return data;
  } catch (error) {
    console.error('Error in saveFolder:', error);
    throw error;
  }
};

export const deleteFolder = async id => {
  try {
    if (!id) throw new Error('id is required field');

    const { data } = await http.delete(`/savedRows/folder/${id}`);

    return data;
  } catch (error) {
    console.error('Error in deleteFolder:', error);
    throw error;
  }
};

export const editRow = async ({ rowId, content, name, folderId }) => {
  try {
    if (!rowId) throw new Error('rowId is required');
    const { data } = await http.put(`/savedRows/${rowId}`, {
      content,
      name,
      folderId,
    });

    return data;
  } catch (error) {
    console.error('Error in editRow:', error);
    throw error;
  }
};

export const deleteRow = async rowId => {
  try {
    if (!rowId) throw new Error('rowId is required');

    const { data } = await http.delete(`/savedRows/${rowId}`);

    return data;
  } catch (error) {
    console.error('Error in deleteRow:', error);
    throw error;
  }
};
