import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { SearchOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';
import { selectedOrganizationSelector } from '../../../../redux/selector';
import { SearchInput } from './UI.styled';
import useCountCardsPerRow from '../_utils/useCountCardsPerRow';
import { ROWS_TO_LOAD } from '../_utils/constants';

const CardsSearch = ({
  setCards,
  cards,
  setLoading,
  searchValue,
  setSearchValue,
  getCards,
  setHasMoreSearchCardsToLoad,
  loadSearchCardsRef,
  activeSortByValue,
  activeFilter,
}) => {
  const selectedOrg = useSelector(selectedOrganizationSelector);
  const debounceTimeout = 800;
  const cardsPerRow = useCountCardsPerRow();
  const [notFound, setNotFound] = useState(false);
  const debouncedFetchCardsData = useMemo(() => {
    return debounce(async value => {
      if (!value) {
        setCards([]);
        return;
      }

      searchForCards(value);
    }, debounceTimeout);
  }, [selectedOrg.id, searchValue, debounceTimeout]);

  // this useEffect will trigger the next one, I could not include searchForCards function call here since cards were still not updated
  useEffect(() => {
    if (searchValue) {
      setCards([]);
    }
  }, [activeSortByValue, activeFilter]);

  // once the above useEffect is triggered and cards are set to an empty array, we can trigger a new request by invoking searchForCards function
  useEffect(() => {
    if (!cards.length && searchValue && !notFound) {
      searchForCards(searchValue);
    }
  }, [cards]);

  const searchForCards = async value => {
    try {
      setLoading(true);
      const skip = cards.length;
      let limit = cardsPerRow * ROWS_TO_LOAD;

      const data = await getCards({
        orgId: selectedOrg.id,
        skip,
        limit,
        searchValue: value,
        sortby: activeSortByValue,
        filter: activeFilter,
      });

      const notFound = !data.result.length && !data.left;
      if (notFound) {
        setCards([]);
      } else {
        setCards(prevCards => [...prevCards, ...data.result]);
      }
      setHasMoreSearchCardsToLoad(data.left);
      setNotFound(notFound);
      setLoading(false);
    } catch (err) {
      console.error('Error loading cards', err);
    }
  };

  loadSearchCardsRef.current = searchForCards;

  useEffect(() => {
    debouncedFetchCardsData(searchValue);

    return () => {
      debouncedFetchCardsData.cancel();
    };
  }, [searchValue, debouncedFetchCardsData]);

  const handleOnChange = e => {
    const value = e.target.value;
    setLoading(!!value);
    setSearchValue(value);
    if (!value) {
      setCards([]);
    }
  };

  return (
    <SearchInput
      placeholder="Search..."
      prefix={<SearchOutlined />}
      size="large"
      value={searchValue}
      onChange={handleOnChange}
      allowClear
    />
  );
};

export default CardsSearch;
