import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { InfoCircleOutlined } from '@ant-design/icons';
import { CircularProgress } from '@material-ui/core';
import {
  selectedOrganizationSelector,
  userDetailSelector,
} from '../../../../redux/selector';
import {
  toast,
  fullloader,
  resetAudienceBuilder,
} from '../../../../redux/action';
import {
  updateTemplate,
  getUserTemplates,
  updateDraft as updateDraftService,
  saveNewDraftService,
} from '../../../../redux/services';
import NewCard from '../NewCard';
import UserTemplateCard from '../UserTemplateCard';
import {
  Container,
  MessageContainer,
  FullLengthContainer,
  Text,
} from '../_PageUI/Page.styled';
import useCountCardsPerRow from '../_utils/useCountCardsPerRow';
import { ROWS_TO_LOAD } from '../_utils/constants';

const SavedTemplatesPage = ({
  activeSortByValue,
  activeFilter,
  cardsBySearch,
  loadingCardsBySearch,
  searchValue,
  hasMoreSearchCardsToLoad,
  loadSearchCardsRef,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const observer = useRef();
  const selectedOrganization = useSelector(selectedOrganizationSelector);
  const userDetail = useSelector(userDetailSelector);
  const [userTemplates, setUserTemplates] = useState([]);
  const [hasMoreTemplatesToLoad, setHasMoreTemplatesToLoad] = useState(false);
  const [loadingMoreSavedTemplates, setLoadingMoreSavedTemplates] =
    useState(false);
  const cardsPerRow = useCountCardsPerRow();
  const hasSomeSavedTemplatesRendered = searchValue
    ? cardsBySearch.length > 0
    : userTemplates.length > 0;

  const loadUserTemplates = useCallback(async () => {
    try {
      setLoadingMoreSavedTemplates(true);
      const skip = userTemplates.length;
      let limit = cardsPerRow * ROWS_TO_LOAD;
      // if there are no templates then fetch one less because first card is a NewCard used for creating templates
      if (!skip) {
        limit -= 1;
      }

      let filter = 'active';
      if (activeFilter === 'starred') {
        filter = 'starred';
      } else if (activeFilter === 'archived') {
        filter = 'archived';
      }

      const data = await getUserTemplates({
        orgId: selectedOrganization.id,
        sortby: activeSortByValue,
        skip,
        limit,
        filter,
      });

      setUserTemplates(prevUserTemplates => [
        ...prevUserTemplates,
        ...data.result,
      ]);
      setHasMoreTemplatesToLoad(data.left);
      setLoadingMoreSavedTemplates(false);
    } catch (e) {
      dispatch(toast('error', 'Error loading saved templates.'));
    }
  }, [
    activeFilter,
    activeSortByValue,
    cardsPerRow,
    dispatch,
    selectedOrganization.id,
    userTemplates.length,
  ]);

  // triggers next useEffect which triggers loadUserTemplates function
  useEffect(() => {
    setUserTemplates([]);
  }, [activeSortByValue, activeFilter, selectedOrganization.id]);

  useEffect(() => {
    if (userTemplates.length === 0 && selectedOrganization.id) {
      dispatch(fullloader(true, 'Loading Your Templates ...'));
      loadUserTemplates().finally(() => {
        dispatch(fullloader(false));
      });
    }
  }, [
    userTemplates.length,
    selectedOrganization.id,
    dispatch,
    loadUserTemplates,
  ]);
  const { draft: draftId, apply } = queryString.parse(location.search);
  const applyingToDraft = apply === '1';

  const lastElemComponentRef = lastElement => {
    if (hasMoreTemplatesToLoad) {
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          if (searchValue) {
            loadSearchCardsRef.current(searchValue);
          } else {
            loadUserTemplates();
          }
        }
      });

      if (lastElement) observer.current.observe(lastElement);
    }
  };

  const openNewTemplate = () => {
    dispatch(resetAudienceBuilder());
    history.push('/dashboard/sendmessage?template=new');
  };

  const unarchiveUserTemplate = async template => {
    try {
      await updateTemplate({
        _id: template._id,
        archived: false,
      });
      dispatch(toast('success', 'Template Unarchived.'));
      // this will trigger loading templates
      setUserTemplates([]);
    } catch (e) {
      dispatch(toast('error', `Error unarchiving the template. ${e.message}`));
    }
  };

  const archiveUserTemplate = async template => {
    try {
      await updateTemplate({
        _id: template._id,
        archived: true,
      });
      dispatch(toast('success', 'Template Archived.'));
      // this will trigger loading templates
      setUserTemplates([]);
    } catch (e) {
      dispatch(toast('error', `Error archiving the template. ${e.message}`));
    }
  };

  const applyUserTemplateToDraft = async template => {
    try {
      dispatch(fullloader(true, 'Applying selected template...'));
      let draftIdentifier = draftId;
      const data = {
        html: template.html,
        design: template.design,
        ...(template.sender && { sender: template.sender }),
        ...(template.senderName && { senderName: template.senderName }),
        ...(template.replyTo && { replyTo: template.replyTo }),
        ...(template.preheader && { preheader: template.preheader }),
        ...(template.subject && { subject: template.subject }),
      };
      if (draftId === 'new') {
        const draft = await saveNewDraftService({
          ...data,
          organizationId: selectedOrganization.id,
          userId: userDetail.id,
        });

        draftIdentifier = draft._id;
      } else {
        await updateDraftService({
          _id: draftId,
          ...data,
        });
      }
      dispatch(fullloader(false));
      history.push(`/dashboard/sendmessage?draft=${draftIdentifier}`);
    } catch (e) {
      dispatch(
        toast('error', `Error applying template to draft. ${e.message}`)
      );
    }
  };

  const openUserTemplate = async template => {
    dispatch(fullloader(true, 'Applying selected template...'));
    const draft = await saveNewDraftService({
      design: template.design,
      html: template.html,
      organizationId: selectedOrganization.id,
      userId: userDetail.id,
    });
    dispatch(resetAudienceBuilder());
    dispatch(fullloader(false));
    history.push(`/dashboard/sendmessage?draft=${draft._id}`);
  };

  const removeUserTemplateFromList = removedUserTemplateId => {
    const filteredUserTemplates = userTemplates.filter(
      template => template._id !== removedUserTemplateId
    );
    setUserTemplates(filteredUserTemplates);
  };

  const savedTemplatesText = useMemo(
    () =>
      `These are templates saved by you and your ${selectedOrganization.organizationName} teammates. Templates help you avoid constantly creating messages from scratch, and will also help your messages maintain a consistent visual style.`,
    [selectedOrganization.organizationName]
  );

  const getSearchResults = () => {
    if (loadingCardsBySearch && !cardsBySearch.length)
      return (
        <FullLengthContainer>
          <CircularProgress size={50} />
        </FullLengthContainer>
      );

    if (cardsBySearch.length)
      return (
        <>
          {cardsBySearch.map(template => (
            <UserTemplateCard
              key={template._id}
              template={template}
              unarchive={() => {
                unarchiveUserTemplate(template);
              }}
              archive={() => {
                archiveUserTemplate(template);
              }}
              onClick={
                applyingToDraft
                  ? () => applyUserTemplateToDraft(template)
                  : () => openUserTemplate(template)
              }
              removeTemplate={removeUserTemplateFromList}
              reloadTemplates={() => {
                //this will trigger reloading templates
                setUserTemplates([]);
              }}
            />
          ))}
          {hasMoreSearchCardsToLoad ? (
            loadingCardsBySearch ? (
              <FullLengthContainer>
                <CircularProgress size={50} />
              </FullLengthContainer>
            ) : (
              <FullLengthContainer ref={lastElemComponentRef} />
            )
          ) : null}
        </>
      );

    return <>No Templates found.</>;
  };

  return (
    <>
      <MessageContainer>
        <InfoCircleOutlined />
        <Text>{savedTemplatesText}</Text>
      </MessageContainer>
      <Container>
        {cardsBySearch.length || loadingCardsBySearch || searchValue ? (
          getSearchResults()
        ) : (
          <>
            {!applyingToDraft && (
              <NewCard
                onClick={openNewTemplate}
                title="Create a New Template"
              />
            )}
            {userTemplates.map(template => (
              <UserTemplateCard
                key={template._id}
                template={template}
                unarchive={() => {
                  unarchiveUserTemplate(template);
                }}
                archive={() => {
                  archiveUserTemplate(template);
                }}
                onClick={
                  applyingToDraft
                    ? () => applyUserTemplateToDraft(template)
                    : () => openUserTemplate(template)
                }
                removeTemplate={removeUserTemplateFromList}
                reloadTemplates={() => {
                  //this will trigger reloading templates
                  setUserTemplates([]);
                }}
              />
            ))}
            {hasSomeSavedTemplatesRendered ? (
              loadingMoreSavedTemplates ? (
                <FullLengthContainer>
                  <CircularProgress size={50} />
                </FullLengthContainer>
              ) : (
                <FullLengthContainer ref={lastElemComponentRef} />
              )
            ) : null}
          </>
        )}
      </Container>
    </>
  );
};

export default SavedTemplatesPage;
