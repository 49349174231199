import React from 'react';
import Modal from '../../MaterialUIModal';
import { deleteFolder } from './api';
import { useToast } from '../../../redux/action';

const FolderDeleteConfirmationModal = ({
  categoryToDelete,
  setDynamicRowsConfiguration,
  setRowCategoryDeleteConfirmationModalVisibility,
  setFolderName,
}) => {
  const { errorToast, successToast } = useToast();
  const { handle: folderId, name: folderName } = categoryToDelete;
  const handleDelete = async () => {
    try {
      if (folderName) {
        setRowCategoryDeleteConfirmationModalVisibility(false);
        setFolderName('');
        await deleteFolder(folderId);
        setDynamicRowsConfiguration(prevConfig => ({
          ...prevConfig,
          externalContentURLs: [
            ...prevConfig.externalContentURLs.filter(
              folder => folder.handle !== folderId
            ),
          ],
        }));
        successToast('Folder is deleted successfully!');
      }
    } catch (e) {
      console.error('Error in handleDelete:', e);
      errorToast('Encountered an error while deleting a folder.');
    }
  };

  const handleCancel = () => {
    setRowCategoryDeleteConfirmationModalVisibility(false);
  };

  return (
    <Modal
      className="templatemodal save-as-modal"
      title="Confirm Deletion"
      onClose={handleCancel}
      primaryButton={{
        label: 'Delete',
        onClick: handleDelete,
        isDelete: true,
      }}
    >
      <p>
        Are you sure you want to permanently delete <em>{folderName} </em>
        Folder and any Saved Rows in it?
      </p>
    </Modal>
  );
};

export default FolderDeleteConfirmationModal;
