import React, { useState } from 'react';
import {
  Drawer,
  IconButton,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@material-ui/core';
import { Close, Description, HighlightOff } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { updateOrganization as updateOrganizationService } from '../../../../../redux/services';
import { userPrivilegesSelector } from '../../../../../redux/selectors/user';
import {
  toast,
  userOrganization,
  useUpdateUIState,
} from '../../../../../redux/action';
import {
  Container,
  Header,
  Content,
  SaveBtn,
  Title,
  Group,
  AddBtn,
  Column,
} from './EditOrgDetailsDrawer.styled';
import { Title as ContentTitle, Row, Bold } from '../GeneralInfo.styled';
import {
  timezones,
  getTimezoneInfo,
  limitCharacters,
  allowOnlyNumericValues,
  isEmpty,
  isEmailValid,
  isValidPhoneNumber,
  isValidWebsite,
  getEmailErrorHelperText,
  getPhoneNumberErrorHelperText,
  getWebsiteErrorHelperText,
} from '../../utils';

const useStyles = makeStyles(() => ({
  selectLabel: {
    background: 'white',
  },
  selectField: {
    color: '#212121',
    '&:focus': {
      backgroundColor: 'transparent',
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
  },
}));

const CORPORATION_TYPES = {
  Nonprofit: 'Nonprofit/Not for Profit (e.g. a 501(c)3 association)',
  'For-Profit': 'For-Profit (e.g. an MLS)',
  Hybrid: 'Hybrid (e.g. a nonprofit association that owns an MLS)',
};

const EditOrgDetailsDrawer = ({
  selectedOrg,
  isEditDetailsDrawerVisible,
  setEditDetailsDrawerVisibility,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const userPrivileges = useSelector(userPrivilegesSelector);
  const { fullLoader } = useUpdateUIState();
  const [organizationName, setOrganizationName] = useState(
    selectedOrg.organizationName
  );
  const [acronym, setAcronym] = useState(selectedOrg.acronym);
  const [email, setEmail] = useState(selectedOrg.email);
  const [phoneNumber, setPhoneNumber] = useState(selectedOrg.phoneNumber);
  const [website, setWebsite] = useState(selectedOrg.website);
  const [memberPortalURL, setMemberPortalURL] = useState(
    selectedOrg.memberPortalURL || ''
  );
  const [timezone, setTimezone] = useState(selectedOrg.timezone?.region || '');
  const [corporationType, setCorporationType] = useState(
    selectedOrg.corporationType
  );
  const [ein, setEIN] = useState(selectedOrg.federalEIN);
  const [emailFromName, setEmailFromName] = useState(selectedOrg.senderName);
  const [textingFromName, setTextingFromName] = useState(
    selectedOrg.textSenderName
  );
  const [fromEmailPrefix, setFromEmailPrefix] = useState(selectedOrg.fromEmail);
  const [fromEmailDomain, setFromEmailDomain] = useState(selectedOrg.domains);
  const [fromTextingNumber, setFromTextingNumber] = useState(
    selectedOrg.fromPhone
  );
  const [addresses, setAddresses] = useState(
    selectedOrg.address?.map(address => ({
      address: address.address || '',
      city: address.city || '',
      state: address.state || '',
      zipcode: address.zipcode || '',
    }))
  );

  const handleAddAddress = () => {
    const lastAddress = addresses[addresses.length - 1];

    if (
      !addresses.length ||
      (lastAddress &&
        lastAddress.address &&
        lastAddress.city &&
        lastAddress.state &&
        lastAddress.zipcode)
    ) {
      setAddresses([
        ...addresses,
        { address: '', city: '', state: '', zipcode: '' },
      ]);
    } else {
      dispatch(
        toast(
          'error',
          'Before creating a new address, ensure that you have completed all the required fields for the existing addresses.'
        )
      );
    }
  };

  const handleAddDomain = () => {
    const lastDomain = fromEmailDomain[fromEmailDomain.length - 1];
    if (lastDomain !== '') {
      setFromEmailDomain([...fromEmailDomain, '']);
    }
  };

  const deleteAddress = index => {
    setAddresses(prevAddresses => prevAddresses.filter((_, i) => i !== index));
  };

  const deleteDomain = index => {
    if (fromEmailDomain.length > 1) {
      const updatedDomains = [...fromEmailDomain];
      updatedDomains.splice(index, 1);
      setFromEmailDomain(updatedDomains);
    }
  };

  const handleChange = (index, field, value) => {
    const updatedAddresses = [...addresses];
    updatedAddresses[index][field] = value;
    setAddresses(updatedAddresses);
  };

  const handleTimeZoneChange = event => {
    setTimezone(event.target.value);
  };

  const closeDrawer = () => {
    setEditDetailsDrawerVisibility(false);
  };

  const trimVal = val => {
    return val && typeof val === 'string' ? val.trim() : '';
  };

  const cleanPhoneNumber = phone => {
    return phone.replace(/\D/g, '');
  };

  const handleSubmit = async () => {
    try {
      const date = new Date();
      if (checkIfRequiredFieldsAreMissing()) return;

      const orgData = {
        id: selectedOrg.id,
        organizationName: trimVal(organizationName),
        acronym: trimVal(acronym),
        address: addresses,
        domains: fromEmailDomain.filter(domain => testDomain(domain)),
        email: trimVal(email),
        fromEmail: trimVal(fromEmailPrefix),
        fromPhone: cleanPhoneNumber(trimVal(fromTextingNumber)),
        memberPortalURL: trimVal(memberPortalURL),
        phoneNumber: cleanPhoneNumber(trimVal(phoneNumber)),
        senderName: trimVal(emailFromName),
        timezone: getTimezoneInfo(timezone),
        updatedDate: date.toString(),
        website,
        federalEIN: trimVal(ein),
        textSenderName: trimVal(textingFromName),
        corporationType,
      };
      fullLoader(true, 'Updating organization...');

      const updatedOrg = await updateOrganizationService(orgData);
      if (!updatedOrg) {
        dispatch(toast('error', 'Failed to update organization.'));
        fullLoader(false);
        return;
      }
      dispatch(userOrganization(updatedOrg));
      dispatch(toast('success', 'Successfully Updated Organization'));
      closeDrawer();
      fullLoader(false);
    } catch (e) {
      dispatch(toast('error', 'Failed Updating Organization.'));
      fullLoader(false);
    }
  };

  const checkIfRequiredFieldsAreMissing = () => {
    const missingRequiredFields = [];
    if (!organizationName) {
      missingRequiredFields.push('Organization Legal Name');
    }

    if (!email) {
      missingRequiredFields.push('Public Email Address');
    }

    if (!phoneNumber) {
      missingRequiredFields.push('Public Phone Number');
    }

    for (const address of addresses) {
      if (
        !address.address ||
        !address.city ||
        !address.state ||
        !address.zipcode
      ) {
        missingRequiredFields.push('Address field');
        break;
      }
    }

    if (!addresses.length) {
      missingRequiredFields.push('Address field');
    }

    if (!website) {
      missingRequiredFields.push('Website');
    }

    if (!corporationType) {
      missingRequiredFields.push('Corporation Type');
    }

    if (!ein) {
      missingRequiredFields.push('Federal Employer ID Number');
    }

    if (!emailFromName) {
      missingRequiredFields.push('Email "From" Name');
    }

    if (!textingFromName) {
      missingRequiredFields.push("Texting 'From' Name");
    }

    if (!fromEmailPrefix) {
      missingRequiredFields.push("'From' Email Prefix");
    }

    for (const domain of fromEmailDomain) {
      if (!domain) {
        missingRequiredFields.push("'From' Email Domain");
        break;
      }
    }

    if (!fromTextingNumber) {
      missingRequiredFields.push("'From' Texting Number");
    }

    if (!missingRequiredFields.length) return false;
    dispatch(
      toast(
        'error',
        `Please fill out all the required fields: ${missingRequiredFields.join(
          ', '
        )}.`
      )
    );

    return true;
  };

  const testDomain = domain => {
    const pattern = /^[a-zA-Z0-9-]+\.([a-zA-Z]{2,})$/;
    return pattern.test(domain);
  };

  return (
    <Drawer
      anchor={'right'}
      onClose={closeDrawer}
      open={isEditDetailsDrawerVisible}
    >
      <Container>
        <Header>
          <Group>
            <IconButton onClick={closeDrawer} style={{ color: 'white' }}>
              <Close />
            </IconButton>
            <Title>Edit: Organization Details</Title>
          </Group>
          <SaveBtn onClick={handleSubmit}>Save</SaveBtn>
        </Header>
        <Content>
          <ContentTitle>
            <Description size="small" />
            Organization Details
          </ContentTitle>
          <TextField
            label="Organization Legal Name*"
            variant="outlined"
            size="small"
            value={organizationName}
            error={isEmpty(organizationName)}
            helperText={isEmpty(organizationName) ? 'Required field' : ''}
            onChange={e => setOrganizationName(e.target.value)}
          />
          <TextField
            label="Acronym"
            variant="outlined"
            size="small"
            value={acronym}
            onChange={e => setAcronym(e.target.value)}
          />
          <TextField
            label="Public Email Address*"
            variant="outlined"
            size="small"
            value={email}
            error={isEmpty(email) || !isEmailValid(email)}
            helperText={getEmailErrorHelperText(email)}
            onChange={e => setEmail(e.target.value)}
          />
          <TextField
            label="Public Phone Number*"
            variant="outlined"
            size="small"
            value={phoneNumber}
            error={isEmpty(phoneNumber) || !isValidPhoneNumber(phoneNumber)}
            helperText={getPhoneNumberErrorHelperText(phoneNumber)}
            onChange={e =>
              setPhoneNumber(allowOnlyNumericValues(e.target.value, 10))
            }
          />
          {addresses.map((address, index) => (
            <Row key={index}>
              <TextField
                label="Street Address*"
                variant="outlined"
                size="small"
                value={address.address}
                error={isEmpty(address.address)}
                helperText={isEmpty(address.address) ? 'Required field' : ''}
                style={{ width: '40%' }}
                onChange={e => handleChange(index, 'address', e.target.value)}
              />
              <TextField
                label="City*"
                variant="outlined"
                size="small"
                value={address.city}
                error={isEmpty(address.city)}
                helperText={isEmpty(address.city) ? 'Required field' : ''}
                style={{ width: '25%' }}
                onChange={e => handleChange(index, 'city', e.target.value)}
              />
              <TextField
                label="State*"
                variant="outlined"
                size="small"
                value={address.state}
                error={isEmpty(address.state)}
                helperText={isEmpty(address.state) ? 'Required field' : ''}
                style={{ width: '12%' }}
                onChange={e => handleChange(index, 'state', e.target.value)}
              />
              <TextField
                label="Zip*"
                variant="outlined"
                size="small"
                value={address.zipcode}
                error={isEmpty(address.zipcode)}
                helperText={isEmpty(address.zipcode) ? 'Required field' : ''}
                style={{ width: '12%' }}
                onChange={e => handleChange(index, 'zipcode', e.target.value)}
              />
              {userPrivileges.isSU && (
                <IconButton
                  onClick={() => deleteAddress(index)}
                  style={{ color: '#ff6161' }}
                >
                  <HighlightOff size="small" />
                </IconButton>
              )}
            </Row>
          ))}
          {userPrivileges.isSU && (
            <AddBtn onClick={handleAddAddress}>+ Add additional address</AddBtn>
          )}
          <TextField
            label="Website*"
            variant="outlined"
            size="small"
            value={website}
            error={isEmpty(website) || !isValidWebsite(website)}
            helperText={getWebsiteErrorHelperText(website)}
            onChange={e => setWebsite(e.target.value)}
          />
          <TextField
            label="Member Portal URL"
            variant="outlined"
            size="small"
            value={memberPortalURL}
            onChange={e => setMemberPortalURL(e.target.value)}
          />
          <FormControl variant="outlined" size="small">
            <InputLabel className={classes.selectLabel}>Time Zone*</InputLabel>
            <Select
              className={classes.selectField}
              value={timezone}
              onChange={handleTimeZoneChange}
              inputProps={{ 'aria-label': 'Time Zone' }}
            >
              {timezones.map(timezone => (
                <MenuItem key={timezone} value={timezone}>
                  {timezone}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" size="small">
            <InputLabel className={classes.selectLabel}>
              Corporation Type*
            </InputLabel>
            <Select
              className={classes.selectField}
              value={corporationType}
              onChange={e => setCorporationType(e.target.value)}
              inputProps={{ 'aria-label': 'Corporation Type' }}
              error={isEmpty(corporationType)}
            >
              <MenuItem disabled value="">
                Corporate Type*
              </MenuItem>
              {Object.entries(CORPORATION_TYPES).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
            {isEmpty(corporationType) && (
              <FormHelperText
                style={{ color: '#F44335' }}
                id="corporation-type-helper-text"
              >
                Required field
              </FormHelperText>
            )}
          </FormControl>
          <TextField
            label="Federal Employer ID Number (EIN)*"
            variant="outlined"
            size="small"
            value={ein}
            error={isEmpty(ein)}
            helperText={isEmpty(ein) ? 'Required field' : ''}
            onChange={e => setEIN(e.target.value)}
          />
          <ContentTitle>
            <Description size="small" />
            Default Sender Details
          </ContentTitle>
          <TextField
            label="Email 'From' Name*"
            variant="outlined"
            size="small"
            value={emailFromName}
            error={isEmpty(emailFromName)}
            helperText={isEmpty(emailFromName) ? 'Required field' : ''}
            onChange={e => setEmailFromName(e.target.value)}
          />
          <TextField
            label="Texting 'From' Name* (15 character limit)"
            variant="outlined"
            size="small"
            value={textingFromName}
            error={isEmpty(textingFromName)}
            helperText={isEmpty(textingFromName) ? 'Required field' : ''}
            onChange={e => setTextingFromName(limitCharacters(e.target.value))}
          />
          <Row>
            <TextField
              label="'From' Email Prefix*"
              variant="outlined"
              size="small"
              value={fromEmailPrefix}
              error={isEmpty(fromEmailPrefix)}
              helperText={isEmpty(fromEmailPrefix) ? 'Required field' : ''}
              style={{ width: '43%' }}
              onChange={e => setFromEmailPrefix(e.target.value)}
            />
            <Column>
              {fromEmailDomain.map((domain, index) => (
                <Row key={index}>
                  <Bold>@</Bold>
                  <TextField
                    label="'From' Email Domain*"
                    variant="outlined"
                    size="small"
                    value={domain}
                    error={isEmpty(domain)}
                    helperText={isEmpty(domain) ? 'Required field' : ''}
                    disabled={!userPrivileges.isSU}
                    style={{ width: '80%' }}
                    onChange={e => {
                      const updatedDomains = [...fromEmailDomain];
                      updatedDomains[index] = e.target.value;
                      setFromEmailDomain(updatedDomains);
                    }}
                  />
                  {userPrivileges.isSU && (
                    <IconButton
                      onClick={() => deleteDomain(index)}
                      style={{ color: '#ff6161' }}
                    >
                      <HighlightOff size="small" />
                    </IconButton>
                  )}
                </Row>
              ))}
            </Column>
          </Row>
          {userPrivileges.isSU && (
            <AddBtn onClick={handleAddDomain}>+ Add additional domain</AddBtn>
          )}
          <TextField
            label="'From' Texting Number*"
            variant="outlined"
            size="small"
            disabled={!userPrivileges.isSU}
            value={fromTextingNumber}
            error={isEmpty(fromTextingNumber)}
            helperText={isEmpty(fromTextingNumber) ? 'Required field' : ''}
            onChange={e => setFromTextingNumber(e.target.value)}
          />
        </Content>
      </Container>
    </Drawer>
  );
};

export default EditOrgDetailsDrawer;
