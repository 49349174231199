import React, { useEffect, useState } from 'react';
import { Drawer } from '../../../../features/automated-campaigns/CampaignView/CampaignView.styled';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getActiveSyncReport } from '../../../../redux/services/lastSyncs';
import { RefreshButton, Title } from './SyncReport.styled';
import TextUsage from '../TextUsage';
import { selectedOrganizationSelector } from '../../../../redux/selector';

const syncTypes = {
  membership: 'Membership',
  billing: 'Billing',
  offices: 'Offices',
  committees: 'Committees',
  committeemembers: 'Committee Members',
  eventsandclasses: 'Events & Classes',
};

const SyncReportDrawer = ({ report, onClose }) => {
  console.log('report', report);
  const organization = useSelector(selectedOrganizationSelector);
  const [activeSyncs, setActiveSyncs] = useState(null);

  useEffect(() => {
    if (report) fetchActiveSyncReport();
  }, [report.id]);

  const fetchActiveSyncReport = async () => {
    //While we already have some full/manual sync data, this actually grabs data from the server cache as it is being updated.
    //This grabs any updates from active full syncs running on Helios for a specific organization.
    const activeReport = await getActiveSyncReport(report.id);
    setActiveSyncs(activeReport);
  };
  const lastSyncTimeDisplay = (name, value) => {
    return (
      <p key={name}>{`${name}: ${
        value ? moment.unix(value).format('YYYY-MM-DD hh:mm A') : 'N/A'
      }`}</p>
    );
  };

  const activeSyncStatusDisplay = () => {
    if (activeSyncs)
      return (
        <>
          {Object.keys(activeSyncs).map(syncName => {
            const activeSyncData = activeSyncs[syncName];
            return (
              <>
                <p>
                  <b>
                    {syncTypes[syncName]} ({activeSyncData.status})
                  </b>
                </p>
                <p>
                  &emsp;
                  {`(${activeSyncData.processedRecords} / ${
                    activeSyncData.totalRecords
                  })    as of ${moment
                    .unix(activeSyncData.time)
                    .format('YYYY-MM-DD hh:mm A')}`}
                </p>
              </>
            );
          })}
        </>
      );
  };

  return (
    <Drawer open onClose={onClose} anchor="right">
      <Drawer.Header>
        <Drawer.CloseButton onClick={onClose} />
        <Drawer.Title>Sync Report</Drawer.Title>
        <RefreshButton onClick={fetchActiveSyncReport}>Refresh</RefreshButton>
      </Drawer.Header>
      <Drawer.Body>
        <>
          <TextUsage orgId={organization.id} />
          <br />
          <Title>Time of last full sync:</Title>
          {lastSyncTimeDisplay('Membership', report.fullMembershipTimestamp)}
          {lastSyncTimeDisplay('Billing', report.fullBillingTimestamp)}
          {lastSyncTimeDisplay('Office', report.fullOfficeTimestamp)}
          {lastSyncTimeDisplay('Committee', report.fullCommitteeTimestamp)}
          {lastSyncTimeDisplay(
            'Events & Classes',
            report.fullEventsAndClassesTimestamp
          )}
          <br />
          <Title>Active full sync progress:</Title>
          {activeSyncs && activeSyncStatusDisplay()}
          <br />
          <Title>Time Since Last Delta Sync:</Title>
          <p>Membership: {report.membershipDelta}</p>
          <p>Basic: {report.basicDelta}</p>
          <p>Contact: {report.contactDelta}</p>
          <p>Billing: {report.billingDelta}</p>
          <p>Office: {report.officeDelta}</p>
          <p>Events And Classes: {report.eventsAndClassesDelta}</p>
          <p>Events Registrations: {report.eventsRegistrationsDelta}</p>
          <p>Courses: {report.coursesDelta}</p>
          <p>List: {report.listDelta}</p>
        </>
      </Drawer.Body>
    </Drawer>
  );
};

export default SyncReportDrawer;
